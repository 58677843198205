<template>
  <div id="about" class="container">
    <div class="about-accent">
      <div class="about-content">
        <h1>{{ ABOUT_HEAD_LINE }}</h1>
        <p>{{ ABOUT_HEAD_LINE_SUB }}</p>
      </div>
    </div>
    <div class="about-bg-accent" ref="aboutBgAccent"></div>
  </div>
</template>

<script>
import { ABOUT_HEAD_LINE, ABOUT_HEAD_LINE_SUB } from "@/utils/constants.ts";
export default {
  name: "AboutSection",
  components: {},
  data() {
    return {
      ABOUT_HEAD_LINE,
      ABOUT_HEAD_LINE_SUB,
    };
  },
  mounted() {
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleScroll() {
      const element = this.$refs.aboutBgAccent;
      const rect = element.getBoundingClientRect();
      const isVisible = rect.top >= 0 && rect.bottom <= window.innerHeight;

      if (isVisible) {
        element.classList.add("transformed");
      } else {
        element.classList.remove("transformed");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.container {
  position: relative;
  width: 100%;
  height: 800px;
  margin: 100px 0;
  background: url("~@/assets/backgrounds/background-white-1.svg") no-repeat
    center;
  background-size: cover;
  display: flex;
  align-items: center;
}

.about-bg-accent {
  position: absolute;
  border-radius: 30px;
  left: 10vw;
  width: 80%;
  height: 60%;
  background-color: var(--deep-teal);
  z-index: 1;
  transition: transform 1s ease-in-out;
}
.about-bg-accent.transformed {
  transform: rotate(4deg);
}

.about-accent {
  position: absolute;
  border-radius: 30px;
  left: 10vw;
  width: 80%;
  height: 60%;
  background: linear-gradient(
    135deg,
    var(--light-beige) 0%,
    var(--off-white) 100%
  ); /* Gradient */
  z-index: 2;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
}

.about-content {
  position: relative;
  z-index: 3;
  top: 1%;
  color: var(--dark-charcoal);
  text-align: center;
  box-sizing: border-box;
  padding: 22px 11%;
  width: 100%;

  p {
    font: 400 20px / 28px var(--main-font);
  }

  h1 {
    font-size: 4rem;
    margin-bottom: 16px;
    width: 100%; /* Ensure it fits within the accent container */
  }
}

/* Media Queries for Mobile Devices */
@media (max-width: 1300px) {
  .about-content p {
    font-size: 18px;
    line-height: 26px;
  }

  .about-content h1 {
    font-size: 3.5rem;
  }
}

@media (max-width: 1200px) {
  .about-content p {
    font-size: 18px;
    line-height: 26px;
  }

  .about-content h1 {
    font-size: 3.5rem;
  }
}

@media (max-width: 992px) {
  .about-content p {
    font-size: 16px;
    line-height: 24px;
  }

  .about-content h1 {
    font-size: 3rem;
  }
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .container {
    height: 400px;
    margin: 100px 0;
  }

  .about-bg-accent,
  .about-accent {
    left: 5vw;
    width: 90%;
    height: 400px;
  }

  .about-content p {
    font-size: 16px;
    line-height: 22px;
  }

  .about-content h1 {
    font-size: 3rem;
  }
}

@media (max-width: 576px) {
  .about-content p {
    font-size: 13px;
    line-height: 20px;
  }

  .about-content h1 {
    font-size: 2rem;
  }
}

@media (max-width: 480px) {
  .container {
    height: 350px;
    margin: 60px 0;
  }

  .about-bg-accent,
  .about-accent {
    left: 5vw;
    width: 90%;
    height: 350px;
  }

  .about-content {
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    padding: 20px;

    h1 {
      font-size: 1.5rem;
      width: 100%;
    }
  }
}
</style>