<template>
  <div id="services" class="services-container">
    <h1 class="heading-primary">What We Do</h1>
    <div class="services-cards">
      <div v-for="service in services" :key="service.id" class="service-card">
        <div class="service-icon">
          <div
            class="icon"
            :style="{
              backgroundImage: `url(${service.icon})`,
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }"
          ></div>
        </div>
        <h3>{{ service.title }}</h3>
        <p>{{ service.shortDescription }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { SERVICES } from "../utils/constants.ts";

export default {
  name: "ServicesSection",
  data() {
    return {
      services: SERVICES.map((service) => ({
        ...service,
        icon: require(`@/assets/icons/${service.icon}.svg`),
      })),
    };
  },
};
</script>

<style scoped>
.services-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 10vw;
  background: var(--light-beige);
}

.heading-primary {
  color: var(--deep-teal);
  padding-bottom: 20px;
}

.services-cards {
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  margin-bottom: 2rem;
  justify-content: center;
}

.service-card {
  padding: 2rem;
  border-radius: 8px;
  transition: transform 0.2s;
  background: var(--off-white);
  align-items: center;
  display: flex;
  flex-direction: column;
  width: calc(33.333% - 6rem);
  height: 20rem;
  box-sizing: border-box;
  text-align: center;

  h3 {
    margin: 0;
    padding-top: 20px;
  }

  p {
    margin: 12px 0 0 0;
  }
}

.service-icon {
  width: 110px;
  height: 110px;
  background: var(--deep-teal);
  border-radius: 50%;
  position: relative;
  margin-bottom: 10px;

  .icon {
    position: absolute;
    width: 60px;
    height: 60px;
    top: 25px;
    left: 25px;
  }
}

.service-card:hover {
  transform: scale(1.09);
  box-shadow: 0 4px 8px rgba(25, 83, 75, 0.7);
}

.service-details {
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  width: 100%;
  max-width: 600px;
  background: var(--off-white);
}

/* Media Queries for Responsiveness */
@media (max-width: 1200px) {
  .service-card {
    width: calc(50% - 4rem); /* 2 cards per row */
  }
}

@media (max-width: 768px) {
  .service-card {
    width: calc(100% - 10rem);

    h3 {
      font-size: 20px;
    }

    p {
      font-size: 14px;
    }
  }
  .service-icon {
    margin-bottom: 0px;
  }
}

@media (max-width: 600px) {
  .service-card {
    width: calc(100% - 4rem);

    h3 {
      font-size: 18px;
    }

    p {
      font-size: 12px;
    }
  }

  .heading-primary {
    font-size: 2.5rem;
  }
}
</style>