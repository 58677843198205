<template>
  <HomePage />
</template>

<script>
import HomePage from "@/components/views/Home.vue";
import "./assets/styles.css";

export default {
  name: "App",
  components: {
    HomePage,
  },
};
</script>
