<template>
  <header class="header">
    <nav class="nav">
      <img :src="logo" alt="Logo" class="logo" />
      <div class="menu-icon" @click="toggleMenu">
        <div class="bar"></div>
        <div class="bar"></div>
        <div class="bar"></div>
      </div>
      <ul
        :class="{ 'nav-list': true, 'nav-list--active': isMenuOpen }"
        @click="isMenuOpen = false"
      >
        <li><a href="#home">Home</a></li>
        <li><a href="#about">About</a></li>
        <li><a href="#services">Services</a></li>
        <li><a href="#contact">Contact</a></li>
      </ul>
    </nav>
  </header>
</template>

<script>
import logo from "@/assets/icons/circle-logo.svg";
export default {
  name: "HeaderPanel",
  data() {
    return {
      logo,
      isMenuOpen: false,
    };
  },
  methods: {
    toggleMenu() {
      this.isMenuOpen = !this.isMenuOpen;
    },
  },
};
</script>

<style scoped>
.header {
  background-color: var(--off-white); /* #202A2F */
  padding: 20px 10vw;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-shadow: 0px 4px 15px rgba(54, 62, 78, 0.15);
  position: sticky;
  top: 0;
  z-index: 1000;
}

.logo {
  width: 100px;
  height: 40px;
}

.nav {
  display: flex;
  align-items: center;
  gap: 30px;
}

.menu-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.menu-icon .bar {
  width: 25px;
  height: 3px;
  background-color: var(--dark-charcoal); /* #FFFFFA */
  margin: 4px 0;
  transition: 0.4s;
}

.nav-list {
  display: flex;
  list-style: none;
}

.nav-list li {
  margin: 0 15px;
}

.nav-list a {
  color: var(--dark-charcoal); /* #FFFFFA */
  text-decoration: none;
  padding: 20px;
}

.nav-list a:hover {
  background: rgba(94, 165, 162, 0.15);
  border-radius: 8px;
  padding: 20px;
  color: var(--deep-teal); /* #124E65 */
}

/* Media Queries for Mobile Devices */
@media (max-width: 768px) {
  .menu-icon {
    display: flex;
    position: absolute;
    right: 10vw;
  }

  .nav-list {
    display: none;
    flex-direction: column;
    position: absolute;
    top: 60px;
    right: 0;
    background-color: var(--off-white); /* #202A2F */
    box-shadow: 0px 4px 15px rgba(54, 62, 78, 0.15);
    border-radius: 10px;
    width: 100%;
  }

  .nav-list a:hover {
    padding: 7px;
  }

  .nav-list--active {
    display: flex;
    z-index: 4;
  }

  .nav-list li {
    margin: 10px 0;
  }

  .nav-list a {
    padding: 10px 20px;
  }
}
</style>