export const HEAD_LINE =
  "Consult, Innovate & Elevate <br> Your Digital Transformation Journey.";
//export const HEAD_LINE = "Connect, Consult & Innovate in Property and Technology.";
export const HEAD_LINE_SUB =
  "Seamlessly Optimize Your Property and IT Operations with Smart Consulting, Advanced Technology, Data-Driven Insights, and AI Solutions.";
export const ABOUT_HEAD_LINE =
  "Empower Your Business with Tech-Driven Solutions.";
export const ABOUT_HEAD_LINE_SUB =
  "We are a team of tech enthusiasts, innovators, and property management experts who are passionate about helping businesses to grow and succeed.";

export const SERVICES = [
  {
    id: 1,
    title: "Business and Digital Strategy",
    shortDescription:
      "Formulating RFPs and providing tailored digital solutions.",
    longDescription:
      "This service focuses on formulating RFPs, understanding business requirements, suggesting bespoke technological solutions, and providing consultation for digital transformation in real estate.",
    icon: "business",
  },
  {
    id: 2,
    title: "Property and Community Management",
    shortDescription:
      "Comprehensive software for property and community management.",
    longDescription:
      "We offer advanced property management software and OA/Community Management solutions to streamline operations.",
    icon: "property",
  },
  {
    id: 3,
    title: "Energy and Smart Solutions",
    shortDescription:
      "AI-powered energy optimization and smart home automation.",
    longDescription:
      "Our solutions include AI-powered energy optimization software and smart locks/home automation systems for modern living.",
    icon: "energy",
  },
  {
    id: 4,
    title: "Customer Relations and AI",
    shortDescription:
      "Enhancing customer engagement with CRM and AI-powered chatbots.",
    longDescription:
      "This service focuses on improving customer relationships with CRM systems and AI-powered chatbots for better customer engagement.",
    icon: "customer",
  },
  {
    id: 5,
    title: "Analytics and Facility Management",
    shortDescription:
      "Mall analytics and facility management software solutions.",
    longDescription:
      "We provide mall analytics software and CaFM (Computer-aided Facility Management) to optimize operational efficiency.",
    icon: "analytics",
  },
  {
    id: 6,
    title: "Software and Development Services",
    shortDescription:
      "Website, app development, and visitor management software.",
    longDescription:
      "Our development services include website and app development, as well as visitor management software tailored to your business needs.",
    icon: "software",
  },
];

export const SERVICE_ITEMS = ["Business and Digital Strategy", "Property and Community Management", "Energy and Smart Solutions", "Customer Relations and AI", "Analytics and Facility Management", "Software and Development Services"];
