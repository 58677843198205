<template>
  <div id="contact" ref="contactForm" class="contact-form-container">
    <div v-if="!isSubmitted" class="contact-form">
      <h1 class="heading-primary">Get In Touch</h1>
      <form @submit.prevent="submitForm">
        <div v-if="isLoading" class="loader">
          <div class="spinner"></div>
          Submitting...
        </div>
        <div v-else>
          <div class="input-group">
            <div class="input-container">
              <input v-model="name" type="text" required />
              <label :class="{ active: name }">Name</label>
            </div>
            <div class="input-container">
              <input v-model="email" type="email" required />
              <label :class="{ active: email }">Email</label>
            </div>
            <div class="input-container">
              <input
                v-model="phone"
                type="tel"
                @input="validatePhone"
                required
              />
              <label :class="{ active: phone }">Phone</label>
            </div>
          </div>
          <div class="dropdown-container">
            <multiselect
              v-model="selectedSubjects"
              :searchable="false"
              :options="options"
              :multiple="true"
              :clear-on-select="false"
              :close-on-select="false"
              :show-labels="false"
              placeholder="Select a service to inquire about"
              @blur="validateSubjects"
            ></multiselect>
            <span v-if="subjectsError" class="error-message"
              >This field is required.</span
            >
          </div>
          <button type="submit" class="form-submit">Send</button>
        </div>
      </form>
    </div>
    <div v-else class="success-message">
      <h1 class="heading-primary">Thank You!</h1>
      <p>
        Your form has been submitted successfully. Someone will contact you
        shortly.
      </p>
    </div>
    <div class="contact-info">
      <div class="info-item">
        <i class="address"></i>
        <p>Meydan Grandstand, 6th floor, Dubai, U.A.E</p>
      </div>
      <div class="info-item">
        <i class="mail"></i>
        <p>info@globalproptech.solutions</p>
      </div>
    </div>
  </div>
</template>

<script>
import "vue-multiselect/dist/vue-multiselect.min.css";
import { ref, onMounted, onUnmounted } from "vue";
import Multiselect from "vue-multiselect";
import { SERVICE_ITEMS } from "@/utils/constants.ts";
import axios from "axios";

export default {
  name: "ContactForm",
  components: {
    Multiselect,
  },
  setup() {
    const name = ref("");
    const email = ref("");
    const phone = ref("");
    const selectedSubjects = ref([]);
    const options = ref(SERVICE_ITEMS);
    const subjectsError = ref(false);
    const isSubmitted = ref(false);
    const isLoading = ref(false);
    const contactForm = ref(null);

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.classList.add("animate-bg");
        } else {
          entry.target.classList.remove("animate-bg");
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.1,
    });

    onMounted(() => {
      if (contactForm.value) {
        observer.observe(contactForm.value);
      }
    });

    onUnmounted(() => {
      if (contactForm.value) {
        observer.unobserve(contactForm.value);
      }
    });

    const submitForm = async () => {
      validateSubjects();
      if (subjectsError.value) {
        return;
      }
      isLoading.value = true;
      // Handle form submission
      console.log("Form submitted:", {
        name: name.value,
        email: email.value,
        phone: phone.value,
        selectedSubjects: selectedSubjects.value,
      });

      try {
        const response = await axios.post(
          `${process.env.VUE_APP_API_BASE_URL}/api/contact`,
          {
            name: name.value,
            email: email.value,
            phone: phone.value,
            selectedSubjects: selectedSubjects.value,
          }
        );
        console.log("Response from backend:", response.data);
        if (response.status === 200) {
          isSubmitted.value = true;
        }
      } catch (error) {
        console.error("Error submitting form:", error);
        alert("There was an error submitting the form. Please try again.");
      } finally {
        isLoading.value = false;
      }
    };

    const validateSubjects = () => {
      console.log(selectedSubjects.value);

      subjectsError.value = selectedSubjects.value.length === 0;
    };

    const validatePhone = (event) => {
      const value = event.target.value;
      event.target.value = value.replace(/[^0-9]/g, "");
      phone.value = event.target.value;
    };

    return {
      name,
      email,
      phone,
      selectedSubjects,
      options,
      subjectsError,
      isSubmitted,
      isLoading,
      submitForm,
      validatePhone,
      validateSubjects,
      contactForm,
      handleIntersection,
    };
  },
};
</script>

<style scoped>
@keyframes animateBackground {
  0% {
    background-position: 100% 100%;
  }
  100% {
    background-position: top;
  }
}

.contact-form-container {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 20px 10vw;
  background: url("~@/assets/backgrounds/background-white.svg") no-repeat center;
  background-size: cover;
  transition: background-position 2s ease-in-out;
}

.contact-form-container.animate-bg {
  animation: animateBackground 4s forwards;
}

.form-submit {
  width: 100%;
}

.success-message {
  text-align: center;
}

.loader {
  text-align: center;
  font-size: 1.2em;
  color: var(--deep-teal);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.spinner {
  border: 4px solid rgba(0, 0, 0, 0.1);
  border-left-color: var(--deep-teal);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
  margin-bottom: 10px;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.contact-form {
  position: relative;
  width: 50%;
  align-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  padding: 40px 0;

  .heading-primary {
    color: var(--deep-teal);
  }

  form {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 10px;

    input {
      border: none;
      border-bottom: 1px solid var(--deep-teal);
      font: 400 18px/22px var(--main-font);
      padding: 0 0 8px 0;
      background: none;
    }

    input:active,
    input:focus {
      outline: none;
    }

    button {
      color: var(--light-beige);
      border: none;
      background: var(--deep-teal-light-beige-gradient);
      border-radius: 8px;
      padding: 10px;
      margin-top: 20px;
      font: 400 18px/22px var(--main-font);

      &:hover {
        background: var(--deep-teal);
        cursor: pointer;
      }
    }
  }

  .input-group {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.input-group {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.input-container {
  position: relative;
  margin-bottom: 20px;
}

label {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: none;
  padding: 0 5px;
  color: #8a8686;
  transition: 0.2s;
  pointer-events: none;
  font: 400 16px/22px var(--main-font);
}

input:focus + label,
label.active {
  top: -10px;
  color: var(--deep-teal);
}

.contact-info {
  padding: 20px 10vw;
  display: flex;
  align-self: center;
  gap: 30px;
}

.info-item {
  margin: 15px 0;
  display: flex;
  align-items: center;

  p {
    font: 400 18px/18px var(--main-font);
  }
}

.phone {
  background: url("~@/assets/icons/phone.svg") no-repeat center;
  width: 20px;
  height: 20px;
}

.mail {
  background: url("~@/assets/icons/mail.svg") no-repeat center;
  width: 20px;
  height: 20px;
}

.address {
  background: url("~@/assets/icons/location.svg") no-repeat center;
  width: 20px;
  height: 20px;
}

.info-item i {
  margin-right: 10px;
}

input,
textarea {
  display: block;
  width: 100%;
  margin: 10px 0;
  padding: 10px;
  border: 1px solid #ccc;
}
.dropdown-container {
  margin: 10px 0;
}

.error-message {
  color: red;
  font-size: 0.875rem;
  margin-top: 0.5rem;
}

::v-deep .multiselect__tag {
  background: var(--light-beige);
  color: var(--dark-carcoal);
  font: 600 14px / 18px var(--main-font);
}

@media (max-width: 600px) {
  .contact-form {
    width: 90%;
    padding: 20px 0;

    .heading-primary {
      font-size: 2.5rem;
    }
  }

  .contact-form form {
    width: 100%;
  }

  .contact-info {
    flex-direction: column;
    align-items: center;
    align-self: center;
    gap: 0px;
    padding: 10px 5vw;

    p {
      font-size: 14px;
    }
  }

  .info-item {
    width: 100%;
    justify-content: center;
    text-align: center;
    margin: 2px 0;
  }
}

/* Medium devices (tablets, 600px to 768px) */
@media (min-width: 601px) and (max-width: 768px) {
  .contact-form {
    width: 70%;
    padding: 30px 0;
  }

  .contact-form form {
    width: 100%;
  }

  .contact-info {
    flex-direction: column;
    align-items: center;
    padding: 15px 7vw;
    gap: 0px;
  }

  .info-item {
    width: 100%;
    justify-content: center;
    text-align: center;
  }
}

/* Large devices (desktops, 768px and up) */
@media (min-width: 768px) {
  .contact-form {
    width: 50%;
    padding: 40px 0;
  }

  .contact-form form {
    width: 100%;
  }

  .contact-info {
    flex-direction: row;
    justify-content: space-between;
    padding: 20px 0;
  }

  .info-item {
    width: auto;
    justify-content: flex-start;
    text-align: left;
  }
}
</style>
<style lang="less">
.multiselect__option--highlight,
.multiselect__option--selected,
.multiselect__option--highlight:hover {
  background: var(--deep-teal) !important;
  color: var(--off-white);
}
</style>