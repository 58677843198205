<template>
  <div id="home" class="container">
    <div class="hero">
      <div class="head-line">
        <h1 v-html="HEAD_LINE"></h1>
        <p class="head-line-sub">{{ HEAD_LINE_SUB }}</p>
        <button class="contact-btn outline-button" @click="scrollToContact">
          Contact Us
        </button>
      </div>
      <div
        v-for="(image, index) in images"
        :key="index"
        class="hero-background"
        :style="{
          backgroundImage: `url(${image})`,
          zIndex: index === currentImage ? 1 : 0,
          opacity: index === currentImage ? 1 : 0,
        }"
        :class="{ 'zoom-out': index === currentImage }"
      >
        <div class="overlay"></div>
      </div>
    </div>
  </div>
</template>

<script>
import business from "@/assets/images/people-hi-fi.webp";
import people from "@/assets/images/people-meeting.webp";
import demo from "@/assets/images/people-demo.webp";
import logo from "@/assets/icons/circle-logo.svg";
import { HEAD_LINE, HEAD_LINE_SUB } from "@/utils/constants.ts";

export default {
  name: "HeroSection",
  components: {},
  data() {
    return {
      images: [business, people, demo],
      currentImage: 0,
      zoomDuration: 20000, // 20 seconds for the zoom animation
      imageChangeInterval: 10000,
      isScrolled: false, // Track scroll state
      logo,
      HEAD_LINE,
      HEAD_LINE_SUB,
    };
  },
  mounted() {
    this.startZoomAnimation();
    window.addEventListener("scroll", this.handleScroll);
  },
  beforeUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    startZoomAnimation() {
      setInterval(() => {
        this.currentImage = (this.currentImage + 1) % this.images.length;
      }, this.imageChangeInterval);
    },
    handleScroll() {
      this.isScrolled = window.scrollY > 0;
    },
    scrollToContact() {
      const contactSection = document.querySelector(".contact-form-container");
      if (contactSection) {
        contactSection.scrollIntoView({ behavior: "smooth" });
      }
    },
  },
};
</script>

<style lang="less" scoped>
@import "~@/assets/styles/button.less";
.container {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.hero {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
  z-index: 1;
  border-bottom-left-radius: 60px; /* Adjust the radius value as needed */
  border-bottom-right-radius: 60px;
}

.head-line {
  position: absolute;
  z-index: 2;
  width: 850px;
  max-width: 80%;
  color: var(--off-white);
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10vw;

  h1 {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 700;
    font-size: 4rem;
    line-height: 60px;

    /* Gradient Text */
    background-image: linear-gradient(90deg, #ebe9e2 25%, #02b69e);
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    text-fill-color: transparent; /* Fallback for browsers that support text-fill-color */
  }
}

.head-line-sub {
  font-size: 18px;
  font-weight: 200;
  max-width: 80%;
}

.contact-btn {
  position: relative;
  z-index: 2;
  width: auto;
  padding: 10px 40px;
  opacity: 1;
  font: 18px var(--main-font);
  background: var(--off-white);
  color: var(--deep-teal);
  font-weight: 600;
}

.contact-btn:hover {
  transform: translateY(-2px);
}

.contact-btn:active {
  transform: translateY(1px);
}

.hero-background {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-size: cover;
  background-position: center;
  transition: opacity 2s ease-in-out, transform 10s ease-in-out;
  opacity: 0;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}

.zoom-out {
  animation: zoomOut 15s ease-in-out forwards;
  animation-fill-mode: forwards;
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.1);
  }
}

@media (max-width: 600px) {
  .head-line {
    width: 90%;
    padding: 5vw;

    h1 {
      font-size: 3rem;
      line-height: 45px;
    }
  }

  .head-line-sub {
    font-size: 14px;
    line-height: 18px;
  }

  .contact-btn {
    padding: 8px 20px;
    font-size: 16px;
  }
}

/* Medium devices (tablets, 600px to 768px) */
@media (min-width: 601px) and (max-width: 768px) {
  .head-line {
    width: 80%;
    padding: 7vw;

    h1 {
      font-size: 3rem;
      line-height: 50px;
    }
  }

  .head-line-sub {
    font-size: 17px;
  }

  .contact-btn {
    padding: 9px 30px;
    font-size: 17px;
  }
}

/* Large devices (desktops, 768px and up) */
@media (min-width: 769px) {
  .head-line {
    width: 850px;
    padding: 10vw;

    h1 {
      font-size: 4rem;
      line-height: 60px;
    }
  }

  .head-line-sub {
    font-size: 18px;
  }

  .contact-btn {
    padding: 10px 40px;
    font-size: 18px;
  }
}
</style>
  