<template>
  <footer class="footer" id="contact">
    <p>&copy; 2024 Global Prop Tech Solutions. All rights reserved.</p>
  </footer>
</template>
  
<script>
export default {
  name: "FooterSection",
};
</script>
  
<style scoped>
.footer {
  background-color: var(--dark-charcoal); /* #202A2F */
  color: var(--off-white); /* #FFFFFA */
  text-align: center;
  padding: 20px;
}

.social-list {
  list-style: none;
  padding: 0;
  display: flex;
  justify-content: center;
  margin-top: 15px;
}

.social-list li {
  margin: 0 10px;
}

.social-list a {
  color: var(--off-white); /* #FFFFFA */
  font-size: 20px;
  transition: color 0.3s ease;
}

.social-list a:hover {
  color: var(--deep-teal); /* #124E65 */
}
</style>
