<template>
  <div>
    <Header />
    <HeroSection />
    <About />
    <ServicesSection />
    <ContactForm />
    <FooterSection />
  </div>
</template>
  
  <script>
import HeroSection from "@/components/Hero.vue";
import FooterSection from "@/components/Footer.vue";
import About from "@/components/About.vue";
import Header from "@/components/Header.vue";
import ServicesSection from "@/components/Services.vue";
import ContactForm from "@/components/form/ContactForm.vue";

export default {
  name: "HomePage",
  components: {
    Header,
    HeroSection,
    FooterSection,
    About,
    ServicesSection,
    ContactForm,
  },
};
</script>
  
  <style scoped>
body {
  margin: 0;
  font-family: Arial, sans-serif;
}
</style>
  